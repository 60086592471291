import React, { useEffect, useState } from "react";
import axios from "axios";
import "../context/Css.css";
import { Helmet } from "react-helmet-async";
import SubPagePcMain from "./SubPagePcMain";
import SubPagePcProduct from "./SubPagePcProduct";
import SubPagePcPrice from "./SubPagePcPrice";
import SubPagePcResource from "./SubPagePcResource";
import SubPagePcMainEN from "./SubPagePcMainEN";
import SubPagePcProductEN from "./SubPagePcProductEN";
import SubPagePcPriceEN from "./SubPagePcPriceEN";
import SubPagePcResourceEN from "./SubPagePcResourceEN";

export default function MainPagePc() {
  const [showContentPc, setShowContentPc] = useState([1, 0, 0, 0]);
  const [showFooterPc, setShowFooterPc] = useState([0, 0, 0, 0]);
  const [zhLanguage, setZhLanguage] = useState(false);

  const [showInquiry, setShowInquiry] = useState(false);
  const [inquiryPhone, setInquirePhone] = useState("");
  const [inquiryName, setInquireName] = useState("");
  const [inquiryText, setInquireText] = useState("");
  const [inquiryHeaderText, setInquireHeaderText] = useState(
    "感谢您对项日葵的关注！请留下您的联系方式和问题，客服将尽快处理并第一时间答复您："
  );

  const showMain = () => {
    setShowContentPc([1, 0, 0, 0]);
    setShowFooterPc([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const showProduct = () => {
    setShowContentPc([0, 1, 0, 0]);
    setShowFooterPc([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const showPrice = () => {
    setShowContentPc([0, 0, 1, 0]);
    setShowFooterPc([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const showResource = () => {
    setShowContentPc([0, 0, 0, 1]);
    setShowFooterPc([0, 0, 0, 0]);
    window.scrollTo(0, 0);
  };
  const shiftLanguage = () => {
    setZhLanguage((zhLanguage) => !zhLanguage);
  };

  useEffect(() => {
    if (showFooterPc.find((el) => el === 1)) {
      const elmnt = document.getElementById(`footer${showFooterPc.findIndex((el) => el === 1)}`);
      window.scrollBy(0, elmnt.offsetHeight);
    }
  }, [showFooterPc]);

  const showReccommend = () => setShowFooterPc([1, 0, 0, 0]);
  const showService = () => setShowFooterPc([0, 1, 0, 0]);
  const showAgreement = () => setShowFooterPc([0, 0, 1, 0]);
  const showAboutUs = () => setShowFooterPc([0, 0, 0, 1]);
  const closeFooter = () => {
    if (showFooterPc.find((el) => el === 1)) {
      const elmnt = document.getElementById(`footer${showFooterPc.findIndex((el) => el === 1)}`);
      window.scrollBy(0, -elmnt.offsetHeight);
    }
    setShowFooterPc([0, 0, 0, 0]);
  };

  const sendInquiry = async () => {
    if (!/^[1][0-9]{10}$/.test(inquiryPhone)) {
      setInquireHeaderText(zhLanguage ? "请输入正确的手机号..." : "Incorrect phone number...");
      return;
    }
    if (!inquiryName) {
      setInquireHeaderText(zhLanguage ? "请输入您的称谓..." : "Please input your name...");
      return;
    }
    if (!inquiryText) {
      setInquireHeaderText(zhLanguage ? "请输入您的问题描述..." : "Please describe your inquiry in text...");
      return;
    }

    const controller = new AbortController();
    const timeoutAxios = setTimeout(() => controller.abort(), 8000);

    const dataInput = {
      phoneNumber: inquiryPhone,
      name: inquiryName.trim(),
      description: inquiryText.trim(),
    };

    try {
      const responseInfo = await axios.post("https://api.sf2Pro.com:9443/public/newTicket", dataInput, {
        signal: controller.signal,
      });

      if (responseInfo.data.suc) {
        setInquireHeaderText(
          zhLanguage
            ? "您的问题已成功发送，客服将尽快联系您！"
            : "Your inquiry has been sent successfully! sf2Pro Helpdesk would contact you soon"
        );

        setTimeout(() => {
          setShowInquiry(false);
          setInquirePhone("");
          setInquireName("");
          setInquireText("");
          setInquireHeaderText(
            zhLanguage
              ? "感谢您对项日葵的关注！请留下您的联系方式和问题，客服将尽快处理并第一时间答复您："
              : "Thanks for your regards! Please input your details and sf2Pro Helpdesk would contact you soon:"
          );
        }, 2000);
      } else {
        setInquireHeaderText(
          zhLanguage
            ? "抱歉，服务器处理异常，烦请发送邮件至 support@sf2Pro.com，多谢！"
            : "Sorry, server no responding...please mail to support@sf2Pro.com, thanks!"
        );
      }
    } catch (error) {
      setInquireHeaderText(
        zhLanguage
          ? "抱歉，发送遇到问题，烦请发送邮件至 support@sf2Pro.com，多谢！"
          : "Sorry, sending message failed...please mail to support@sf2Pro.com, thanks!"
      );
    } finally {
      clearTimeout(timeoutAxios);
    }
  };

  const startInquiry = () => {
    window.scrollTo(0, 0);
    setInquireHeaderText(
      zhLanguage
        ? "感谢您对项日葵的关注！请留下您的联系方式和问题，客服将尽快处理并第一时间答复您："
        : "Thanks for your regards! Please input your details and sf2Pro Helpdesk would contact you soon:"
    );
    setShowInquiry(true);
  };

  const endInquiry = () => {
    setInquirePhone("");
    setInquireName("");
    setInquireText("");
    setInquireHeaderText(
      zhLanguage
        ? "感谢您对项日葵的关注！请留下您的联系方式和问题，客服将尽快处理并第一时间答复您："
        : "Thanks for your regards! Please input your details and sf2Pro Helpdesk would contact you soon:"
    );
    setShowInquiry(false);
  };

  return (
    <div className="pcHome">
      <Helmet>
        <title>项日葵</title>
        <meta name="description" content="供需双方的协同平台，专属服务团队的工作空间" />
        <meta name="keywords" content="项日葵,服务管理,目标管理,sf2Pro" />
      </Helmet>
      <div className="pcPageTop">
        <img className="PicPcPageTop" src={require("../assets/picPcPageTop.png")} alt="项日葵"></img>
      </div>
      <div className="pcContent">
        {zhLanguage && (
          <div className="pcHeader">
            <img
              className="logoSFwText"
              src={require("../assets/logoSFwText.png")}
              alt="项日葵"
              onClick={showMain}
            ></img>
            <div className="navBarPc">
              <div className="navButtons">
                <button className="navPcHeader" onClick={showMain}>
                  首页
                </button>
                <button className="navPcHeader" onClick={showProduct}>
                  产品
                </button>
                <button className="navPcHeader" onClick={showPrice}>
                  价格
                </button>
                <button className="navPcHeader" onClick={showResource}>
                  资源
                </button>
                <button className="shiftLang" onClick={shiftLanguage}>
                  EN
                </button>
              </div>

              <a href="#" target={"_blank"} rel="noreferrer" id="userRegister">
                注册
              </a>
              <a href="#" target={"_blank"} rel="noreferrer" id="userLogon">
                登录
              </a>
            </div>
          </div>
        )}

        {!zhLanguage && (
          <div className="pcHeader">
            <img
              className="logoSFwText"
              src={require("../assets/logoSFwTextEN.png")}
              alt="sf2Pro"
              onClick={showMain}
            ></img>
            <div className="navBarPc">
              <div className="navButtons">
                <button className="navPcHeader" onClick={showMain}>
                  Home
                </button>
                <button className="navPcHeader" onClick={showProduct}>
                  Product
                </button>
                <button className="navPcHeader" onClick={showPrice}>
                  Price
                </button>
                <button className="navPcHeader" onClick={showResource}>
                  Resource
                </button>
                <button className="shiftLang" onClick={shiftLanguage}>
                  ZH
                </button>
              </div>

              <a href="#" target={"_blank"} rel="noreferrer" id="userRegister">
                Register
              </a>
              <a href="#" target={"_blank"} rel="noreferrer" id="userLogon">
                Login
              </a>
            </div>
          </div>
        )}

        {showContentPc[0] === 1 && zhLanguage && (
          <SubPagePcMain goProduct={showProduct} goResource={showResource}></SubPagePcMain>
        )}
        {showContentPc[1] === 1 && zhLanguage && <SubPagePcProduct goResource={showResource}></SubPagePcProduct>}
        {showContentPc[2] === 1 && zhLanguage && <SubPagePcPrice goResource={showResource}></SubPagePcPrice>}
        {showContentPc[3] === 1 && zhLanguage && <SubPagePcResource></SubPagePcResource>}

        {showContentPc[0] === 1 && !zhLanguage && (
          <SubPagePcMainEN goProduct={showProduct} goResource={showResource}></SubPagePcMainEN>
        )}
        {showContentPc[1] === 1 && !zhLanguage && <SubPagePcProductEN goResource={showResource}></SubPagePcProductEN>}
        {showContentPc[2] === 1 && !zhLanguage && <SubPagePcPriceEN goResource={showResource}></SubPagePcPriceEN>}
        {showContentPc[3] === 1 && !zhLanguage && <SubPagePcResourceEN></SubPagePcResourceEN>}

        {showFooterPc[0] === 1 && zhLanguage && (
          <div className="footerWrapper" id="footer0">
            <p className="footerText1">感谢您对项日葵的推荐！请复制以下链接发给您的朋友（手机端请长按）</p>
            <div className="pcDescMain">
              <p className="secSubTitleS">网页端用户注册</p>
              <p className="directLink">https://sf.sf2Pro.com/register</p>
            </div>
            <br />
            <div className="pcDescMain">
              <p className="secSubTitleS">移动端APP下载</p>
              <p className="directLink">https://sf.sf2Pro.com/</p>
            </div>

            <img className="closeFooterShow" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
          </div>
        )}
        {showFooterPc[0] === 1 && !zhLanguage && (
          <div className="footerWrapper" id="footer0">
            <p className="footerText1">
              Appreciate your recommendation! Please copy below link and send it to your friends
            </p>
            <div className="pcDescMain">
              <p className="secSubTitleS">Web Register</p>
              <p className="directLink">https://sf.sf2Pro.com/register</p>
            </div>
            <br />
            <div className="pcDescMain">
              <p className="secSubTitleS">APP Download</p>
              <p className="directLink">https://sf.sf2Pro.com/</p>
            </div>

            <img
              className="closeFooterShow"
              src={require("../assets/undo.png")}
              alt="Cancel"
              onClick={closeFooter}
            ></img>
          </div>
        )}

        {showFooterPc[1] === 1 && zhLanguage && (
          <div className="footerWrapper" id="footer1">
            <p className="footerText1">在项日葵中，您可通过以下方式获取服务或支持</p>
            <div className="footerService">
              <div className="footerServiceType">
                <h3 className="secTitleS">自助服务</h3>
                <h3 className="secDescriptionScenter">免费</h3>
                <p className="secDescriptionScenter">
                  <br />
                  搜索帮助文档
                  <br />
                  搜索技术说明
                  <br />
                  搜索常见问题
                </p>
              </div>
              <div className="footerServiceType">
                <h3 className="secTitleS">项日葵客服</h3>
                <h3 className="secDescriptionScenter">免费</h3>
                <p className="secDescriptionScenter">
                  <br />
                  用户反馈
                  <br />
                  在线客服
                  <br />
                  &nbsp;
                </p>
              </div>
              <div className="footerServiceType">
                <h3 className="secTitleS">增值服务</h3>
                <h3 className="secDescriptionScenter">收费</h3>
                <p className="secDescriptionScenter">
                  <br />
                  定制业务指标
                  <br />
                  VIP客服
                  <br />
                  &nbsp;
                </p>
              </div>
            </div>
            <img className="closeFooterShow" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
          </div>
        )}
        {showFooterPc[1] === 1 && !zhLanguage && (
          <div className="footerWrapper" id="footer1">
            <p className="footerText1">Get sf2Pro support by following ways</p>
            <div className="footerService">
              <div className="footerServiceType">
                <h3 className="secTitleS">Self-Service</h3>
                <h3 className="secDescriptionScenter">Free</h3>
                <p className="secDescriptionScenter">
                  <br />
                  Online Help
                  <br />
                  Specification
                  <br />
                  FAQ
                </p>
              </div>
              <div className="footerServiceType">
                <h3 className="secTitleS">sf2Pro Helpdesk</h3>
                <h3 className="secDescriptionScenter">Free</h3>
                <p className="secDescriptionScenter">
                  <br />
                  Service Ticket
                  <br />
                  sf2Pro Online
                  <br />
                  &nbsp;
                </p>
              </div>
              <div className="footerServiceType">
                <h3 className="secTitleS">Value-added Service</h3>
                <h3 className="secDescriptionScenter">With a Fee</h3>
                <p className="secDescriptionScenter">
                  <br />
                  Customize system
                  <br />
                  VIP Support
                  <br />
                  &nbsp;
                </p>
              </div>
            </div>
            <img
              className="closeFooterShow"
              src={require("../assets/undo.png")}
              alt="Cancel"
              onClick={closeFooter}
            ></img>
          </div>
        )}

        {showFooterPc[2] === 1 && zhLanguage && (
          <div className="footerWrapper" id="footer2">
            <p className="footerText1">请耐心阅读以下文档，如有疑问可随时联系客服</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <a
              className="pcDirectInline"
              href={"https://cos-dev-public.sf2pro.com/files/sf2ProAgreementTermOfServiceEN.png"}
              target={"_blank"}
              rel="noreferrer"
            >
              项日葵用户服务协议
            </a>
            <p>&nbsp;</p>
            <a
              className="pcDirectInline"
              href={"https://cos-dev-public.sf2pro.com/files/sf2ProAgreementPrivacyProtectionEN.png"}
              target={"_blank"}
              rel="noreferrer"
            >
              项日葵隐私保护协议
            </a>
            <img className="closeFooterShow" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
          </div>
        )}
        {showFooterPc[2] === 1 && !zhLanguage && (
          <div className="footerWrapper" id="footer2">
            <p className="footerText1">Please read below documents carefully or call sf2Pro for any questions</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <a
              className="pcDirectInline"
              href={"https://cos-dev-public.sf2pro.com/files/sf2ProAgreementTermOfServiceEN.png"}
              target={"_blank"}
              rel="noreferrer"
            >
              User Service Agreement
            </a>
            <p>&nbsp;</p>
            <a
              className="pcDirectInline"
              href={"https://cos-dev-public.sf2pro.com/files/sf2ProAgreementPrivacyProtectionEN.png"}
              target={"_blank"}
              rel="noreferrer"
            >
              Privacy Protection Agreement
            </a>
            <img
              className="closeFooterShow"
              src={require("../assets/undo.png")}
              alt="Cancel"
              onClick={closeFooter}
            ></img>
          </div>
        )}

        {showFooterPc[3] === 1 && zhLanguage && (
          <div className="footerWrapper" id="footer3">
            <img className="logoYGwText" src={require("../assets/yigeLogoFull.png")} alt="sf2Pro"></img>
            <p>&nbsp;</p>
            <p className="footerText2">
              sf2Pro是由专业人士建立的初创公司，sf2Pro的业务聚焦企业或团队数字化管理。sf2Pro合伙人的平均数字化经验为23年，所曾供职的公司均为世界一流数字化企业，像IBM、埃森哲、普华永道等。
              <br />
              <br />
              sf2Pro人基于高科技领域的服务实践，打造了这款适用于服务团队的项日葵系统，其产品设计也真正把握了服务行业的痛点和难点。sf2Pro人认为在一眼可见的未来，现代服务业必将成为经济发展的支柱，sf2Pro人将与您一起迎接这个机遇和挑战！
              <br />
              <br />
            </p>
            <p>&nbsp;</p>
            <p className="footerText3">support@sf2Pro.com 上海sf2Pro信息技术有限公司</p>
            <img className="closeFooterShow" src={require("../assets/undo.png")} alt="撤销" onClick={closeFooter}></img>
          </div>
        )}
        {showFooterPc[3] === 1 && !zhLanguage && (
          <div className="footerWrapper" id="footer3">
            <img className="logoYGwText" src={require("../assets/yigeLogoFull.png")} alt="sf2Pro"></img>
            <p>&nbsp;</p>
            <p className="footerText2">
              sf2Pro is a startup company founded by professionals focusing on developing a product for team management.
              sf2Pro founders have 20+ years of experience in top digital companies like IBM, Accenture and PWC.
              <br />
              <br />
              sf2Pro team has developed sf2Pro based on service practices in high-tech consulting industries. sfPro’s
              designs therefore, have well captured the pain points and core values in the service industry. sf2Pro
              sincerely believes the modern service industry will be the engine in the future economy. We are ready to
              work with you to embrace that future.
              <br />
              <br />
            </p>
            <p>&nbsp;</p>
            <p className="footerText3">support@sf2Pro.com Sunflower to Professionals</p>
            <img
              className="closeFooterShow"
              src={require("../assets/undo.png")}
              alt="Cancel"
              onClick={closeFooter}
            ></img>
          </div>
        )}

        {zhLanguage && (
          <div className="pcFooter">
            <div className="footerButtons">
              <button className="btnPcFooter" onClick={showReccommend}>
                推荐
              </button>
              <button className="btnPcFooter" onClick={showService}>
                服务
              </button>
              <button className="btnPcFooter" onClick={showAgreement}>
                协议
              </button>
              <button className="btnPcFooter" onClick={showAboutUs}>
                关于
              </button>
            </div>
            <a href="#" target={"_blank"} rel="external noreferrer" style={{ textDecoration: "none" }}>
              <p className="textPcFooter">Sunflower to Professional Pte. Ltd.</p>
            </a>
          </div>
        )}
        {!zhLanguage && (
          <div className="pcFooter">
            <div className="footerButtons">
              <button className="btnPcFooter" onClick={showReccommend}>
                Share
              </button>
              <button className="btnPcFooter" onClick={showService}>
                Support
              </button>
              <button className="btnPcFooter" onClick={showAgreement}>
                Agreement
              </button>
              <button className="btnPcFooter" onClick={showAboutUs}>
                About
              </button>
            </div>
            <a href="#" target={"_blank"} rel="external noreferrer" style={{ textDecoration: "none" }}>
              <p className="textPcFooter">Sunflower to Professional Pte. Ltd..</p>
            </a>
          </div>
        )}
      </div>
      <div className="pcPageRightBottom">
        <img
          className="sidePicBottomRight"
          src={require("../assets/chat.png")}
          alt="Inquiry"
          onClick={startInquiry}
        ></img>
      </div>

      {showInquiry && zhLanguage && (
        <div className="pcInquiryBackground">
          <div className="pcInquiryForm">
            <div className="pcInquiryInputBoxHeaderText">
              <p className="pcInquiryHeaderText">{inquiryHeaderText}</p>
            </div>
            <div className="pcInquiryInputBox">
              <h3 className="inquiryTitleS">电话</h3>
              <input
                className="pcInquiryInput"
                type={"text"}
                placeholder={"请输入您的手机号"}
                value={inquiryPhone}
                onChange={(input) => setInquirePhone(input.target.value)}
              ></input>
            </div>
            <div className="pcInquiryInputBox">
              <h3 className="inquiryTitleS">称谓</h3>
              <input
                className="pcInquiryInput"
                type={"text"}
                placeholder={"请输入您的姓名"}
                value={inquiryName}
                onChange={(input) => setInquireName(input.target.value)}
              ></input>
            </div>
            <div className="pcInquiryInputBoxText">
              <h3 className="inquiryTitleS">描述</h3>
              <textarea
                className="pcInquiryInputText"
                type={"text"}
                placeholder={"请输入您的问题"}
                name={inquiryText}
                onChange={(input) => setInquireText(input.target.value)}
              ></textarea>
            </div>
            <div className="pcInquiryButtonBox">
              <button onClick={endInquiry}>退出</button>
              <button onClick={sendInquiry}>发送</button>
            </div>
          </div>
        </div>
      )}

      {showInquiry && !zhLanguage && (
        <div className="pcInquiryBackground">
          <div className="pcInquiryForm">
            <div className="pcInquiryInputBoxHeaderText">
              <p className="pcInquiryHeaderText">{inquiryHeaderText}</p>
            </div>
            <div className="pcInquiryInputBox">
              <h3 className="inquiryTitleS">Phone</h3>
              <input
                className="pcInquiryInput"
                type={"text"}
                placeholder={"Please input your phone number"}
                value={inquiryPhone}
                onChange={(input) => setInquirePhone(input.target.value)}
              ></input>
            </div>
            <div className="pcInquiryInputBox">
              <h3 className="inquiryTitleS">Name</h3>
              <input
                className="pcInquiryInput"
                type={"text"}
                placeholder={"Please input your name"}
                value={inquiryName}
                onChange={(input) => setInquireName(input.target.value)}
              ></input>
            </div>
            <div className="pcInquiryInputBoxText">
              <h3 className="inquiryTitleS">Text</h3>
              <textarea
                className="pcInquiryInputText"
                placeholder={"Please describe your question in details"}
                name={inquiryText}
                onChange={(input) => setInquireText(input.target.value)}
              ></textarea>
            </div>
            <div className="pcInquiryButtonBox">
              <button onClick={endInquiry}>Quit</button>
              <button onClick={sendInquiry}>Send</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
