import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnPrice(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>sf2Pro - Enterprise-level Service at Consumer Grade Price</title>
        <meta
          name="description"
          content="sf2Pro classifies studios with three types: Free, Standard and VIP; sf2Pro service is subscription-based, users could pay or renew studios in man-days"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,sf2Pro"
        />
      </Helmet>
      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">sf2Pro Pricing</h3>
          <p className="secDescriptionPn">
            Enterprise-level Service at Consumer Grade Price
            <br />
            <br />
            <br />
          </p>
          <h3 className="secTitlePn">Studio Type</h3>
          <p className="secDescriptionPn">
            sf2Pro studios are divided into three types: Free, Standard and VIP.
            <br />
            Studio Admins, i.e. the initial studio registrants, are responsible for operating and closing the studios.
            &nbsp;
          </p>
          <img className="pnImageInSection" src={require("../assets/meetingrmS.jpg")} alt="Online studio"></img>
        </div>
      </div>

      <div className="pnPriceSubSection">
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">Free Studio</h3>
          <h3 className="secTitleS">Free</h3>
          <p className="secDescriptionScenter">
            Gifted 90 Man-day
            <br />
            Storage on actual usage
            <br />
            SMS on actual usage
            <br />
            <br />
          </p>
        </div>
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">Standard Studio</h3>
          <h3 className="secTitleS">0.00 USD / Man-day</h3>
          <p className="secDescriptionScenter">
            Subscription-based
            <br />
            200 G-day free storage
            <br />
            30 free SMS messages
            <br />
            <br />
          </p>
        </div>
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">VIP Studio</h3>
          <h3 className="secTitleS">0.00 USD / Man-day (900+)</h3>
          <p className="secDescriptionScenter">
            Paid with VIP support
            <br />
            900 G-day free storage
            <br />
            180 free SMS messages
            <br />
            <br />
          </p>
        </div>

        <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 32 }}>
          For more details, please refer to &nbsp;
          <a
            className="pnDirectInline"
            href={"https://cos-dev-public.sf2pro.com/files/sf2ProStudioTypeEN.png"}
            target={"_blank"}
            rel="noreferrer"
          >
            <br />
            Learn about Studio Type
          </a>
        </p>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Man-day Consumption Rules</h3>
          <p className="secDescriptionPn">
            sf2Pro service is subscription-based. Users need to pay or renew studios in man-days.
            <br />
            <br />
            Studio Admins can suspend studios so that the studio charging is suspended as well.
          </p>
          <img className="pnImageInSection" src={require("../assets/sfScreenInvoiceEN.png")} alt="Pricing"></img>
          <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 16 }}>
            For more details, please refer to &nbsp;
            <a
              className="pnDirectInline"
              href={"https://cos-dev-public.sf2pro.com/files/sf2ProStudioPricingEN.png"}
              target={"_blank"}
              rel="noreferrer"
            >
              Learn about sf2Pro Pricing
            </a>
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Invoice and Balance Transfer</h3>
          <p className="secDescriptionPn">
            Studio Admin can transfer man-days to other studios as long as the current studio balance is positive.
            <br />
            <br />
            Studio Admin can renew a studio before the studio balance is less than zero, or close it when it's not
            required.
          </p>
          <img className="pnImageInSection" src={require("../assets/sfScreenAccTransferEN.png")} alt="Pricing"></img>
          <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 16 }}>
            sf2Pro supports e-invoices and refunds with justified reasons.
          </p>
        </div>
      </div>
    </div>
  );
}
